@import "variables";

*{
    /* width */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a6a6a6;
    border-radius: 3px;
  }

  &:root{    
    scrollbar-base-color: transparent;
    scrollbar-track-color: transparent!important;
  }

  outline: none !important;
}


.dropdown-toggle::after{
  float:right;
}

.dropdown-menu.show {
  width: 100%;
}

.custom-dropdown-toggle {
  height: 100%;
  width: 100%;
  padding-top: 0.1em;
}

.custom-dropdown-toggle svg::after {
  content: none;
}

.modal-success{
  margin-top: 10%;
}

.aside-radio{
  margin-left: 2%;
}

.table-header-dashed{
  width: 44px;
  height: 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $font-color-more-light;
}

.table-header-bold{
  width: 64px;
  height: 19px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $font-color-black;
}

.create-new{
  vertical-align: sub;
  width: 76px;
  height: 19px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
  cursor: pointer;
}

.container-fluid{
  padding-left: 0 !important;
}

.nav-icon{
  width: 20px;
  height: 20px;
}

input[type=radio]{
  visibility: hidden;
  position: absolute;
}

input[type=radio] + label:before{
  content: " ";
  display:inline-block;
  vertical-align: baseline;
  width: 15px;
  height: 15px;
  box-shadow: inset 3px 3px 0 0 #dddddd;
  border: solid 1px #c6c6c6;
  background-color: #f7f7f7;
  cursor: pointer;
}

input[type=radio]:checked + label:before{
  background:#419bf9;
  width: 15px;
  height: 15px;
  box-shadow: none;
  cursor: pointer;
}

input[type=radio] + label:before{
  border-radius:50%;
}

.radio-buttom{
  overflow: hidden;
  max-height: 18px;
}

.radio-buttom > label{
  margin-bottom: 0 !important;
  font-weight: 500;
}

input[type=radio]:checked+label:after {
  content: "";
  display: block;
  position: relative;
  top: -14px;
  left: 4px;
  border-radius: 12px;
  width: 7px;
  height: 7px;
  border: solid 1px #419bf9;
  background-color: #eff8ff;
  cursor: pointer;
}

.radio-button-text{
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
}

.manage-detail{

  .nav-tabs {
    border-bottom: none;
  }

  .tab-pane{
    text-align: left;
  }


  .nav-item {
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #353535;
    font-size: 14px;
    border: none;

    &:hover{
      opacity: 0.8;
    }

    &.active{
      background-color: #fafbfc!important;
      color: #419bf9!important;
      border-bottom: 1px solid #419bf9!important;
      font-weight: 600;
    }    
}

}

.mb-1r{
  margin-bottom: 1rem !important;
}

.editor-toolbar{
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px !important;
  background-color: #fafbfc;
}

.save-button{
  width: 79px;
  height: 31px;
  background-color: #0082ed !important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.cancel-button{
  width: 98px;
  height: 31px;
  background-color: #d5d5d5!important;
  color: white !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  font-size: 14px;
}

.ConfirmButton{
  cursor: pointer !important;
  margin-bottom: 1px;
}

.react-contextmenu-item svg{
  vertical-align: text-top;
}

.react-contextmenu-item:after{
  content: '' !important;
}

.context-menu-arrow{
  position: absolute;
  right: 14px;
  margin-top: 4px;
}

.markdown-container{
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding-bottom: 26px;
}

.empty-markdown-text{
  width: 189px;
  height: 25px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: var(--gunmetal);
  background-color: #fffced;
}

.markdown-row{
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e8e8e8;
}

.three-dots{
  white-space: pre;
  overflow: hidden; 
  text-overflow: ellipsis;
  max-height: 15px;
}

.no-padding-top{
  padding-top: 0 !important;
}

.border-dashed-left-toolbar{
  border-left: 1px dashed #c5c5c5 !important;
  height: 14px;
  margin-top: 0px;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
input:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */

.dropdown-item div {
  -webkit-appearance: unset;
}

.icon-container-hover{
  -webkit-appearance: none;

  .react-contextmenu-wrapper{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 0px 4px 0 0;
    &:hover{
      background-color: #95acc2;
        .icon-more{
          color: white !important;
        }

        .icon-tag{
          color: white !important;
        }
    }
    &:active{
      background-color: #95acc2;
      color: white;
    }
  }
}
// Style to markdown table
.content-wrapper {
  table {
      line-height: 1.7;
      max-width: 100%;
      overflow: auto;
      border: 1px solid #f6f6f6;
      border-collapse: collapse;
      border-spacing: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      th {
          text-align: center;
          font-weight: 700;
          border: 1px solid #efefef;
          padding: 2px 6px;
          background-color: #f5f7fa;
          word-break: break-word;
      }

      tr{
          border: 1px solid #efefef;
      }

      td {
          border: 1px solid #efefef;
          text-align: left;
          padding: 2px 15px;
          word-break: break-word;
          min-width: 60px;
      }
  }
}


.ct-modal{
  .modal-content{
      width: 610px;
      // height: 405px;
      border-radius: 8px 8px 2px 2px;
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
      border: solid 1px #d6d6d6;
      background-color: #ffffff;
      border-top: 10px solid #419bf9;
      padding: 40px 30px;
      margin-left: auto;
      margin-right: auto;
  }
}

// modals
.cu-modal{
  .modal-content{
      width: 906px;
      border-radius: 8px 8px 2px 2px;
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
      border: solid 1px #d6d6d6;
      background-color: #ffffff;
      border-top: 10px solid #419bf9;
      padding: 40px 30px;
      margin-left: auto;
      margin-right: auto;
  }
  
}

.darkModal > div > div {
  background-color: #272f36 !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: 10px solid #419bf9 !important;
  box-shadow: none !important;
}

.lightModal{
  > div > div{
    background-color: #ffffff !important;
  }
}