.markdown-detail{
  overflow-x: hidden;
  overflow-y: hidden;
  height: 190px;
  width: 609px;
  margin-bottom: 20px; 
  padding-right: 0;  
}

.markdown-btn-container{
  position: relative;
  top: 58px;
  left: 334px;
  z-index: 10;
}

.markdown-detail .editor-preview-active{
  background: white;
  border: none;
  padding: 0px;
  font-size: 13px;
  color: #5e6667;
}

.markdown-detail .CodeMirror-scroll{
  box-sizing: content-box !important;
  //min-height: 15vh !important;
}

.markdown-detail::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #aaa;
}

.markdown-detail::-webkit-scrollbar {
  background-color: #eee;
  width: 5px;
}

.CodeMirror{
  height: 100% !important;
  border-radius: 3px!important;
  border: none!important;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
}

[id^='simplemde-editor-']{
  height: 100% !important;
}

.markdown-title{
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: bold;
}

.editor-preview-full ul {
  padding-left: 40px;
}

.editor-preview>p{
  margin-bottom: 13px !important;
}

.editor-preview-full ol {
  padding-left: 40px;
}