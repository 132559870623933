@import "Common/styles/componentes.scss";

.if-modal{
    .modal-content{
        width: 450px;
        height: fit-content;
        border-radius: 8px 8px 2px 2px;
        box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
        border: solid 1px #d6d6d6;
        background-color: #ffffff;
        border-top: 10px solid #419bf9;
        padding: 40px 30px;
        margin-left: auto;
        margin-right: auto;

        .if-modal-header {
            display: inline-flex;

            .if-modal-title {
                width: 249px;
                height: 32px;
                font-size: 21.5px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.49;
                letter-spacing: normal;
                color: #419bf9;
            }



            .f-btn{
                width: 89px;
                height: 34px;
                border-radius: 3px;
                border: solid 1px #d4d7e5;

                &.btn-cancel {
                    background-color: white;
                    @extend .text-black-14;
                }

                &.btn-action{
                    width: 194px;
                    @extend .text-white-14;
                    font-weight: bold;
                    background-color: #c5c5c5;
                    cursor: default;

                    svg {
                        margin-left: 10px;
                        font-size: 0.7em;
                    }

                    &.active {
                        background-color: #88cc3f;
                        cursor: pointer;
                    }
                }

            }

            .modal-icon {
                width: 44px;
                height: 44px;
                object-fit: contain;
                top: 2px;
                position: relative;

            }
        }

        .if-modal-body{
            .nav-tabs {
                border-bottom: none;
                @extend .text-black-14;

                .nav-item {
                    border: none!important;
                    font-weight: normal;
                    margin-right: 28px;

                    &:hover {
                        border: none!important;
                        color: #419bf9!important;
                    }        


                    &.active {
                        color: #419bf9!important;
                        border: none;
                        border-bottom: 2px solid #419bf9!important;
                        font-weight: 600;
                    }
                }
            }

            
        }    
    }
}