@import "variables";

.custom-container-fluid{
  padding: $container-padding-top $container-padding-right $container-padding-bottom $container-padding-left;
  height: calc(100% - 6px);
}

// TEXTS
%text {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

%text-black {
  @extend %text;
  color: $font-color-black!important;
}

%text-white {
  @extend %text;
  color: $white!important;
}

%text-light {
  @extend %text;
  color: $font-color-more-light!important;
}

%text-light-2 {
  @extend %text;
  color: $font-color-light!important;
}

%text-blue {
  @extend %text;
  color: $font-color-link!important;
}

%text-gunmetal {
  @extend %text;
  color: $icon-gunmetal!important;
}

.text-gunmetal-12{
  @extend %text-gunmetal;
  font-size: 12px!important;
  font-weight: 500;
}

.text-black-12{
  @extend %text-black;
  font-size: 12px!important;
  font-weight: 500;
}

.text-black-13{
  @extend %text;
  font-size: 13px!important;
  color: $font-color-black-2;
}

.text-black-14{
  @extend %text-black;
  font-size: 14px!important;
  font-weight: 300;
}

.text-black-17{
  @extend %text-black;
  font-size: 17px!important;
  font-weight: 400;
}

.text-black-19 {
  @extend %text-black;
  font-size: 19px;
  font-weight: 500;
}

.text-black-21 {
  @extend %text-black;
  font-size: 21px;
  font-weight: 600;
}

.text-black-28{
  @extend %text-black;
  font-size: 28px!important;
  font-weight: 600;
}

.text-white-9{
  @extend %text-white;
  font-size: 9px!important;
  font-weight: bold;
}

.text-white-12{
  @extend %text-white;
  font-size: 12px!important;
  font-weight: bold;
}

.text-light-12{
  @extend %text-light;
  font-size: 12px!important;
}

.text-white-13{
  @extend %text-white;
  font-size: 13px!important;
}

.text-white-14{
  @extend %text-white;
  font-size: 14px!important;
}


.text-white-17{
  @extend %text-white;
  font-size: 17px!important;
  font-weight: 600;
}

.text-light2-14{
  @extend %text-light-2;
  font-size: 14px!important;  
  font-weight: 300;
}

.text-blue-12{
  @extend %text-blue;
  font-size: 12px!important;
  font-weight: bold;
}

// SEPARATORS
.separator-dashed-right{
  height: calc(100% - 4px);
  border-right: 1px dashed $font-color-light;
  padding-right: 13px;
}

.separator-dashed-bottom{
  margin-right: auto;
  margin-left: auto;
  margin-top: 9px;
  margin-bottom: 9px;
  width: calc(100% - 100px);
  border-bottom: 1px dashed $font-color-light;
}

.separator-dashed-bottom-no-margin{
  margin-top: 9px;
  margin-bottom: 9px;
  border-bottom: 1px dashed $font-color-light;
}

%status-box {
  width: 11px;
  height: 12px;
  border-radius: 3px;
  margin-top: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.02);
}

.status-box-default{
  @extend %status-box;
  background-color: $radio-btn-on;
}

.status-box-critical{
  @extend %status-box;
  background-color: $color-confirmed-box;
}


// BACKGROUNDS
%bg-severity{
  @extend .text-white-17;
  border-radius: 3px;
  padding-top: 14px;
  padding-right: 18px;
  padding-bottom: 8px;
  margin-right: 1px;
  padding-left: 8px;
}

.bg-critical{
  @extend %bg-severity;
  background-color: $color-critical;
}

.bg-high{
  @extend %bg-severity;
  background-color: $color-high;
}

.bg-medium{
  @extend %bg-severity;
  background-color: $color-medium;
}

.bg-low{
  @extend %bg-severity;
  background-color: $color-low;
}

.bg-informational{
  @extend %bg-severity;
  background-color: $color-informational !important;
}

.bg-unclassified{
  @extend %bg-severity;
  background-color: $color-unclassified;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f4f7 !important;
  border-radius: 3px;
}


// CUSTOM SELECT
.faraday-select-container{
  .faraday-select {
    width: 100%;
    height: 41px;
    padding: 7px 13px 5px 20px;
    border: none;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $background;
    cursor: pointer;
    @extend .text-black-21;

    .faraday-select-option {
      background: white;
      @extend .text-black-14;
      &:hover{
        background-color: $background-row-1;
      }
    }

    &:focus{
      outline: 0;
      box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
      background-color: #eff1f2;
    }



  }

  .faraday-select-icon {
    position: relative;
    bottom: 32px;
    right: 25px;
  }
}

//ACTIONS BUTTONS
.action-button{
  width: 18px!important;
  height: 18px!important;
  cursor: pointer;
}


//Manage
.custom-filters-section{
  .severity-tag{
    width: 37px;
    height: 32px; 
    padding-top: 8px;  
    font-size: 12px!important; 
  }
}

.severity-tag{
  width: 25px;
  height: 18px;
  border-radius: 4px;
  box-shadow: inset 0 2px 0 0 #d2d7de;
  border: solid 1px #d4dae1;
  background-color: #fafbfc;
  font-size: 12px;
  color: #b1b9c2!important;
  padding-top: 3px;
  text-align: center;
  font-weight: 700;
  cursor: pointer!important;  
  

  &.critical{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-critical;
    border: solid 1px $color-critical;
    background-color: $color-critical;
    @extend .text-white-9;
  }

  &.high{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-high;
    border: solid 1px $color-high;
    background-color: $color-high;
    @extend .text-white-9;
  }

  &.medium{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-medium;
    border: solid 1px $color-medium;
    background-color: $color-medium;
    @extend .text-white-9;
  }

  &.low{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-low;
    border: solid 1px $color-low;
    background-color: $color-low;
    @extend .text-white-9;
  }

  &.informational{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-informational;
    border: solid 1px $color-informational;
    background-color: $color-informational;
    @extend .text-white-9;
  }

  &.unclassified{
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 0 0 $color-unclassified;
    border: solid 1px $color-unclassified;
    background-color: $color-unclassified;
    @extend .text-white-9;
  }

}


// Buttons
.btn-copy-link{
  width: 71px;
  height: 18px;
  border-radius: 3px;
  background-color: #419bf9;
  text-align: center;
  cursor: pointer;
  @extend .text-white-13;
}


.faraday-btn{
  width: 90px;
  height: 39px;
  border-radius: 3px;

  &.btn-gray{
    background-color: #e1eaf3;
    @extend .text-black-14;
  }

  &.btn-green{
    background-color: #88cc3f;
    font-weight: 600;
    @extend .text-white-14;
  }

  &.btn-blue{
    background-color: #419bf9;
    font-weight: 600;
    @extend .text-white-14;
  }
  
}


// Modals

.custom-modal{
  .modal-content{
    border-radius: 2px!important;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03)!important;
    background-color: #ffffff!important;
    border: none;

    .modal-title {      
      padding: 30px 22px 10px 27px;
      .warn-icon{
        margin-right: 14px;
        font-size: 24px;
        position: relative;
        bottom: 5px;
      }

      .close-icon-container{
        width: 37px;
        height: 37px;        
        text-align: center;
        align-items: center;
        padding: 6px;
        position: relative;
        top: -10px;
        border-radius: 50%;
        cursor: pointer;

        svg{
          color: $font-color-light;
        }

        &:hover{
          background-color: #e1eaf3;
          svg{
            color: white;
          }
        }
      }
    }

    .modal-footer {
      border: none;

      .btn-danger{
        @extend .text-white-14;
        font-weight: 400;
        width: 83px;
        height: 39px;
        border-radius: 3px;
        background-color: #ea3158;
        border: none;
      }

      .btn-success{
        @extend .text-white-14;
        font-weight: 400;
        width: 83px;
        height: 39px;
        border-radius: 3px;
        background-color: #88cc3f;      
        border: none;
      }

      .btn-secondary{
        @extend .text-black-14;
        width: 89px;
        height: 39px;
        border-radius: 3px;
        background-color: #e1eaf3;
        border: none;
      }
    }

  }
}

// Text Inputs
//Filters
.custom-input-text{
  width: 244px;
  height: 31px;
  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: #f8f7f0;
  border: none;
  padding-top: 5px!important;
  font-weight: 300;
  color: #afb1bc;
  font-size: 14.5px;
}


// Tags
.faraday-tag{
  height: 27px;
  opacity: 0.6;
  border-radius: 2px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #cbcbcb;
  background-color: #ffffff;  
  padding: 3px 6px 5px 10px;
  font-weight: 400;
  @extend .text-black-12; 

  .quit-tag{
    font-size: 15px;
    bottom: 2px;
    position: relative;
    -webkit-appearance: none;

    &:hover{
      svg{
        color: #080808!important;
      }
    }

  }
}

.preview-add-tag{
  @extend .text-black-13;   
  margin-left: 13px;
  padding: 5px 15px;
  cursor: pointer;
  border: solid 1px transparent;

  &:hover{
    font-weight: 500;    
    border-radius: 3px;
    border: solid 1px #d8dee5 !important;
    background-color: #f1f4f7 !important;  
  }
}

.cancel-button{
  width: 89px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px #707070;
  background-color: #ffffff !important;
  color: #1c2243;
  font-weight: 400 !important;
}

.next-button{
  width: 89px;
  height: 34px;
  border-radius: 2px;
  background-color: #0082ed;
  color: #ffffff;
  font-weight: 400 !important;
}