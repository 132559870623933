iframe
{
    display: none;
}

@font-face {
  font-family: 'Sequel Sans';
  src:
    url('./fonts/SequelSans/Sequel_Sans_Head_Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sequel Sans';
  src:
    url('./fonts/SequelSans/Sequel_Sans_Head_Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sequel Sans';
  src:
    url('./fonts/SequelSans/Sequel_Sans_Head_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Sequel Sans';
  src:
    url('./fonts/SequelSans/Sequel_Sans_Head_SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Sequel Sans';
  src:
    url('./fonts/SequelSans/Sequel_Sans_Head_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CentraNo2';
  src:
    url('./fonts/CentraNo2/CentraNo2-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'CentraNo2';
  src:
    url('./fonts/CentraNo2/CentraNo2-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}