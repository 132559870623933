.custom-background input {
    background-color: #edf2f7;
}

.icon2-container{
    margin-top: 12px;
    cursor: pointer;
    .input-list-icon {
        font-size: 12px;
    }

    &:hover{
        background-color: #95acc2;   
        .input-list-icon {
            font-size: 11px; 
            color: white!important;       
        }   
    }

}